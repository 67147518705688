var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingState)?_c('b-card',{staticClass:"d-flex"},[_c('Loading')],1):_vm._e(),(_vm.showSettingsField)?_c('div',[_c('b-card',[_c('b-card-text',{staticClass:"text-center position-relative"},[_c('h2',{domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.title))}}),_c('img',{staticClass:"position-absolute",staticStyle:{"right":"33px","top":"20px","cursor":"pointer"},attrs:{"width":"35px","src":require("@/assets/duplioAsset/icons/helpCircle.png")},on:{"click":function($event){_vm.guideModal = !_vm.guideModal}}})]),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"container"},[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{staticClass:"d-flex p-2 flex-column",staticStyle:{"gap":"1.5rem"},attrs:{"xl":"6"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[_c('b-card-title',{domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.uniqueTitle))}}),_c('p',{staticClass:"mb-0",domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.uniqueSubtitle))}}),(_vm.settingsList)?_vm._l((_vm.settingsList.transactionUnique),function(item,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"xl":"7"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t(
                                                            _vm.locale.chooseField,
                                                            {
                                                                text: item.field,
                                                            }
                                                        )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                        var errors = ref.errors;
return [_c('p',{staticClass:"pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm.$t( _vm.locale.chooseField, { text: item.field, } ))+" ")]),_c('v-select',{staticClass:"per-page-selector-send-method mt-2 d-inline-block",attrs:{"clearable":false,"options":_vm.transactionUnique[
                                                                _vm.transactionField
                                                            ][item.field],"placeholder":_vm.$t(
                                                                _vm.locale.chooseField,
                                                                {
                                                                    text: item.field,
                                                                }
                                                            )},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})]}}],null,true)})],1)],1)}):_vm._e()],2)]),_c('b-col',{staticClass:"d-flex p-2 flex-column",staticStyle:{"gap":"1.5rem"},attrs:{"xl":"6"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[_c('b-card-title',{domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.notifTitle))}}),_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(
                                            _vm.settingsList.notify_about_new_duplicates
                                        ),callback:function ($$v) {_vm.$set(_vm.settingsList, "notify_about_new_duplicates", $$v)},expression:"\n                                            settingsList.notify_about_new_duplicates\n                                        "}},[_vm._v(" "+_vm._s(_vm.$t(_vm.locale.notifSubtitle))+" ")]),_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":_vm.notificationEmail,"name":_vm.$t(_vm.locale.notif)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t(_vm.locale.notif)},model:{value:(
                                                        _vm.settingsList.notification_email
                                                    ),callback:function ($$v) {_vm.$set(_vm.settingsList, "notification_email", $$v)},expression:"\n                                                        settingsList.notification_email\n                                                    "}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})]}}],null,false,677161224)})],1)],1)],1)]),_c('b-col',{staticClass:"d-flex my-5 justify-content-center",attrs:{"xl":"12","sm":"12"}},[_c('div',{staticStyle:{"width":"300px"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}}],attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.updateConfiguration(
                                                _vm.settingsList
                                            )}}},[(_vm.loading)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t('Message.loading'))+"... ")],1):_c('span',{domProps:{"textContent":_vm._s(
                                                _vm.$t('SettingPageCard.save')
                                            )}})])],1)])],1)],1)],1)])],1)],1):_vm._e(),_c('b-modal',{ref:"modal",attrs:{"ok-only":"","hide-footer":"","ok-variant":"primary","ok-title":_vm.$t('SubscriptionCard.ModalConfirm'),"modal-class":"modal-primary","centered":"","clickToClose":"","title":_vm.$t(_vm.locale.guide)},on:{"shown":_vm.generateImage},model:{value:(_vm.guideModal),callback:function ($$v) {_vm.guideModal=$$v},expression:"guideModal"}},[_c('b-card-text',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[_c('p',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.guideSubtitle))}})]),_vm._l((_vm.guideText),function(dataText,index){return _c('b-card',{key:index,staticClass:"border border-secondaryColor"},[_c('span',{staticClass:"spanGuides",domProps:{"innerHTML":_vm._s(dataText[_vm.guide])}})])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }